import { app } from "@@/main.js";
import * as Sentry from "@sentry/vue";
import env from "@/env";

if ("production" === import.meta.env.MODE) {
	Sentry.init({
		app,
		dsn: env.SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router: globalThis.$router }),
			Sentry.replayIntegration(),
			Sentry.captureConsoleIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			/^https:\/\/panel\.startrainingcenter\.pl\/api/,
			/^https:\/\/panel-dev\.startrainingcenter\.pl\/api/,
			/^https:\/\/panel-stg\.startrainingcenter\.pl\/api/,
		],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,
	});
}

app.mount("#app");
