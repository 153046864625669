import { defineStore } from "pinia";
import { AlertTypeEnum } from "@@/enums/AlertTypeEnum";
import useId from "@@/composables/useId";

export const useAlertsStore = defineStore("alerts", {
	state: () => ({
		alerts: [],
		timeout: 5000,
	}),

	getters: {
		getAlerts: (state) => {
			return state.alerts;
		},
	},

	actions: {
		add(alert) {
			this.pushAlert(alert);
		},
		addInfo(alert) {
			this.pushAlert(alert, AlertTypeEnum.INFO);
		},
		info(alert) {
			return this.addInfo(alert);
		},
		addOk(alert) {
			this.addSuccess(alert);
		},
		ok(alert) {
			return this.addSuccess(alert);
		},
		addSuccess(alert) {
			this.pushAlert(alert, AlertTypeEnum.SUCCESS);
		},
		success(alert) {
			return this.addSuccess(alert);
		},
		addWarning(alert) {
			this.pushAlert(alert, AlertTypeEnum.WARNING);
		},
		warning(alert) {
			return this.addWarning(alert);
		},
		addError(alert) {
			this.pushAlert(alert, AlertTypeEnum.ERROR);
		},
		error(alert) {
			return this.addError(alert);
		},

		remove(alertToRemove) {
			this.alerts = this.alerts.filter((alert) => alert.id !== alertToRemove.id);
		},

		pushAlert(alert = null, type = null) {
			const { idS4 } = useId();

			let note = {
				id: `alert-id--${idS4()}`,
				title: "",
				content: "",
				type: type ? type : AlertTypeEnum.INFO,
				timeout: this.timeout,
				props: {},
			};

			if (typeof alert === "string")
				alert = {
					content: alert,
				};

			this.alerts.push({
				...note,
				...alert,
			});
		},
	},
});
